import { deleteNullProperties } from "modules/base/utilities/Actions";
import { serverGeneratePath } from "modules/base/utilities/Navigation";
import PrivateApi from "modules/core/utilities/PrivateApi";
import {
  URL_ALL_VIRTUAL_MACHINES,
  URL_CONTABO_VIRTUAL_MACHINE,
  URL_CONTABO_VIRTUAL_MACHINE_CHANGE_DISPLAY_NAME,
  URL_CONTABO_VIRTUAL_MACHINE_CHANGE_IMAGE,
  URL_CONTABO_VIRTUAL_MACHINE_CREATE,
  URL_CONTABO_VIRTUAL_MACHINE_IMAGES,
  URL_CONTABO_VIRTUAL_MACHINE_RESET_PASSWORD,
  URL_CONTABO_VIRTUAL_MACHINE_RESTART,
  URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOTS,
  URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOT_RESTORE,
  URL_CONTABO_VIRTUAL_MACHINE_START,
  URL_CONTABO_VIRTUAL_MACHINE_STOP,
  URL_CONTABO_VIRTUAL_MACHINE_TAKE_SNAPSHOT,
  URL_VIRTUAL_MACHINE,
  URL_VIRTUAL_MACHINES,
  URL_VIRTUAL_MACHINE_BACKUPS,
  URL_VIRTUAL_MACHINE_BACKUP_RESTORE,
  URL_VIRTUAL_MACHINE_BACKUP_TEMPLATE,
  URL_VIRTUAL_MACHINE_BACKUP_TEMPLATES,
  URL_VIRTUAL_MACHINE_CHANGE_BACKUP_TEMPLATE,
  URL_VIRTUAL_MACHINE_CHANGE_DISPLAY_NAME,
  URL_VIRTUAL_MACHINE_CHANGE_IMAGE,
  URL_VIRTUAL_MACHINE_CHANGE_TEMPLATE,
  URL_VIRTUAL_MACHINE_CLONE,
  URL_VIRTUAL_MACHINE_CREATE,
  URL_VIRTUAL_MACHINE_DELETE,
  URL_VIRTUAL_MACHINE_DISABLE_BACKUP,
  URL_VIRTUAL_MACHINE_IMAGES,
  URL_VIRTUAL_MACHINE_LOCATION,
  URL_VIRTUAL_MACHINE_LOCATIONS,
  URL_VIRTUAL_MACHINE_PASSWORD_RESET,
  URL_VIRTUAL_MACHINE_PAUSE,
  URL_VIRTUAL_MACHINE_REBUILD,
  URL_VIRTUAL_MACHINE_RESTART,
  URL_VIRTUAL_MACHINE_SNAPSHOT,
  URL_VIRTUAL_MACHINE_SOFT_REBOOT,
  URL_VIRTUAL_MACHINE_START,
  URL_VIRTUAL_MACHINE_STOP,
  URL_VIRTUAL_MACHINE_TAKE_BACKUP,
  URL_VIRTUAL_MACHINE_TEMPLATE,
  URL_VIRTUAL_MACHINE_TEMPLATES,
  URL_VIRTUAL_MACHINE_UNPAUSE,
} from "modules/servers/Constants";
import {
  VirtualMachine,
  VirtualMachineBackup,
  VirtualMachineBackupTemplate,
  VirtualMachineImage,
  VirtualMachineLocation,
  VirtualMachineTemplate,
} from "modules/servers/Models";

/**
 * @class
 * @name ServersApiActions
 * @description
 * This class is used to make API calls to the servers API.
 */
export default class ServersApiActions extends PrivateApi {
  /**
   * @function
   * @name ServersApiActions#getVirtualMachineLocations
   * @description
   * Get the list of virtual machine locations.
   * @returns {locations: Array<VirtualMachineLocation>, error: Error, isLoading: boolean}
   */
  getVirtualMachineLocations() {
    const { data, error, isLoading } = this.getAPIRequest(
      `${URL_VIRTUAL_MACHINE_LOCATIONS}`
    );
    const virtualMachineLocations = new VirtualMachineLocation();
    const locations = virtualMachineLocations.fromArray(data);
    return { locations, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachineLocation
   * @description
   * Get the virtual machine location.
   * @returns {location: VirtualMachineLocation, error: Error, isLoading: boolean}
   */
  getVirtualMachineLocation(id) {
    const { data, error, isLoading } = this.getAPIRequest(
      serverGeneratePath(URL_VIRTUAL_MACHINE_LOCATION, { id })
    );
    const virtualMachineLocation = new VirtualMachineLocation(data);
    return { location: virtualMachineLocation, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachineTemplates
   * @description
   * Get the list of virtual machine templates.
   * @returns {templates: Array<VirtualMachineTemplate>, error: Error, isLoading: boolean}
   */
  getVirtualMachineTemplates(cluster_ids = null) {
    const url = cluster_ids
      ? `${URL_VIRTUAL_MACHINE_TEMPLATES}?clusters__in=${cluster_ids}`
      : URL_VIRTUAL_MACHINE_TEMPLATES;
    const { data, error, isLoading } = this.getAPIRequest(url);
    const virtualMachineTemplates = new VirtualMachineTemplate();
    const templates = virtualMachineTemplates.fromArray(data);
    return { templates, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachineTemplate
   * @description
   * Get the virtual machine template.
   * @returns {template: VirtualMachineTemplate, error: Error, isLoading: boolean}
   */
  getVirtualMachineTemplate(id) {
    const { data, error, isLoading } = this.getAPIRequest(
      serverGeneratePath(URL_VIRTUAL_MACHINE_TEMPLATE, { id })
    );
    const virtualMachineTemplate = new VirtualMachineTemplate(data);
    return { template: virtualMachineTemplate, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachineBackupTemplates
   * @description
   * Get the list of virtual machine backup templates.
   * @returns {templates: Array<VirtualMachineBackupTemplate>, error: Error, isLoading: boolean}
   */
  getVirtualMachineBackupTemplates(template_ids = null) {
    const url = template_ids
      ? `${URL_VIRTUAL_MACHINE_BACKUP_TEMPLATES}?templates__in=${template_ids}`
      : `${URL_VIRTUAL_MACHINE_BACKUP_TEMPLATES}?templates__isnull=true`;
    const { data, error, isLoading } = this.getAPIRequest(url);
    const virtualMachineBackupTemplate = new VirtualMachineBackupTemplate();
    const templates = virtualMachineBackupTemplate.fromArray(data);
    return { templates, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachineBackupTemplate
   * @description
   * Get the virtual machine backup template.
   * @returns {template: VirtualMachineBackupTemplate, error: Error, isLoading: boolean}
   */
  getVirtualMachineBackupTemplate(id) {
    const { data, error, isLoading } = this.getAPIRequest(
      serverGeneratePath(URL_VIRTUAL_MACHINE_BACKUP_TEMPLATE, { id })
    );
    const virtualMachineBackupTemplate = new VirtualMachineBackupTemplate(data);
    return { template: virtualMachineBackupTemplate, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachineImages
   * @description
   * Get the list of virtual machine images.
   * @returns {images: Array<VirtualMachineImage>, error: Error, isLoading: boolean}
   */
  getVirtualMachineImages(selectedPlatform = "haverster", cluster_ids = null) {
    let platform_url = URL_VIRTUAL_MACHINE_IMAGES;
    if (selectedPlatform === "contabo" || selectedPlatform === "ctb") {
      platform_url = URL_CONTABO_VIRTUAL_MACHINE_IMAGES;
    }
    const url = cluster_ids
      ? `${platform_url}?clusters__in=${cluster_ids}`
      : platform_url;
    const { data, error, isLoading } = this.getAPIRequest(url);
    const virtualMachineImages = new VirtualMachineImage();
    const images = virtualMachineImages.fromArray(data);
    return { images, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#createVirtualMachine
   * @description
   * Create a virtual machine.
   * @param {Object} payload
   * @param {Function} successCallback
   * @param {Function} errorCallback
   */
  createVirtualMachine(
    payload,
    platform,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    let url = URL_VIRTUAL_MACHINE_CREATE;
    if (platform === "contabo" || platform === "ctb") {
      url = URL_CONTABO_VIRTUAL_MACHINE_CREATE;
    }
    this.postAPIRequest(url, payload, successCallback, errorCallback);
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachines
   * @description
   * Get the list of virtual machines without updated state.
   * @param limit - number
   * @param page - number
   * @param search - string
   * @param filters - string
   * @returns {{isLoading: boolean, count: number, error: any, vms: *[]}}
   */
  getVirtualMachines(limit, page, search, filters) {
    const params = new URLSearchParams(deleteNullProperties(filters));
    const baseUrl = `${URL_ALL_VIRTUAL_MACHINES}?page=${page}&limit=${limit}`;
    if (search && search !== "") {
      params.append("search", search);
    }
    const url = filters ? `${baseUrl}&${params.toString()}` : baseUrl;
    const {
      data = { results: [] },
      error,
      isLoading,
    } = this.getAPIRequest(url);
    const virtualMachine = new VirtualMachine();
    const vms = virtualMachine.fromArray(data.results);
    const { count = 0 } = data;
    return { vms, count, error, isLoading };
  }

  /**
   * @function
   * @name ServersApiActions#getVirtualMachinesWithState
   * @description
   * Get the list of virtual machines with updated state.
   * @param limit - number
   * @param page - number
   * @param search - string
   * @param filters - object
   * @returns {{isLoading: boolean, count: number, error: any, vms: *[]}}
   */
  getVirtualMachinesWithState(limit, page, search, filters) {
    const params = new URLSearchParams(deleteNullProperties(filters));
    const baseUrl = `${URL_ALL_VIRTUAL_MACHINES}?page=${page}&limit=${limit}&with_state=true`;
    if (search && search !== "") {
      params.append("search", search);
    }
    const url = filters ? `${baseUrl}&${params.toString()}` : baseUrl;
    const {
      data = { results: [] },
      error,
      isLoading,
    } = this.getAPIRequest(url, 10000);
    const virtualMachine = new VirtualMachine();
    const vms = virtualMachine.fromArray(data.results);
    const { count = 0 } = data;
    return { vms, count, error, isLoading };
  }

  /** *
   * Get a virtual machine
   * @param id
   * @param vm_type
   * @returns {{isLoading: boolean, vm: VirtualMachine, error: any}}
   */
  getVirtualMachine(id, vm_type) {
    let url = URL_VIRTUAL_MACHINE;
    if (vm_type === "ctb" || vm_type === "contabo") {
      url = URL_CONTABO_VIRTUAL_MACHINE;
    }
    console.log(vm_type);
    const { data, error, isLoading } = this.getAPIRequest(
      serverGeneratePath(url, { id })
    );
    const vm = new VirtualMachine(data);
    return { vm, error, isLoading };
  }

  /** *
   * Stop a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  stopVirtualMachine(
    id,
    platform,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    let base_url = URL_VIRTUAL_MACHINE_STOP;
    if (platform === "ctb") {
      base_url = URL_CONTABO_VIRTUAL_MACHINE_STOP;
    }
    const url = serverGeneratePath(base_url, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Pause a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  pauseVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_PAUSE, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Unpause a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  unPauseVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_UNPAUSE, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Soft reboot a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  softRebootVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_SOFT_REBOOT, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Restart a virtual machine
   * @param id
   * @param platform
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  restartVirtualMachine(
    id,
    vm_type,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    let action_url = URL_VIRTUAL_MACHINE_RESTART;
    if (vm_type === "ctb") {
      action_url = URL_CONTABO_VIRTUAL_MACHINE_RESTART;
    }
    const url = serverGeneratePath(action_url, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Start a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  startVirtualMachine(
    id,
    platform,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    let start_url = URL_VIRTUAL_MACHINE_START;
    if (platform === "ctb") {
      start_url = URL_CONTABO_VIRTUAL_MACHINE_START;
    }
    const url = serverGeneratePath(start_url, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Snap shot a virtual machine
   * @param id
   * @param platform
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  snapshotVirtualMachine(
    id,
    platform,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    let base_url = URL_VIRTUAL_MACHINE_SNAPSHOT;
    if (platform === "ctb" || platform === "contabo") {
      base_url = URL_CONTABO_VIRTUAL_MACHINE_TAKE_SNAPSHOT;
    }
    const url = serverGeneratePath(base_url, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Backup a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  backupVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_TAKE_BACKUP, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /** *
   * Delete a virtual machine
   * @param id
   *  @param {function} successCallback
   *  @param {function} errorCallback
   */
  deleteVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_DELETE, { id });
    this.deleteAPIRequest(url, successCallback, errorCallback, [
      URL_VIRTUAL_MACHINES,
    ]);
  }

  /**
   * Disable virtual machine backups
   * @param id
   * @param successCallback
   * @param errorCallback
   */
  disableVirtualMachineBackups(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_DISABLE_BACKUP, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback);
  }

  /**
   * Change virtual machine backup template
   * @param id
   * @param backup_template
   * @param successCallback
   * @param errorCallback
   */
  changeVirtualMachineBackupTemplate(
    id,
    backup_template,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const payload = { backup_template };
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_CHANGE_BACKUP_TEMPLATE, {
      id,
    });
    this.postAPIRequest(url, payload, successCallback, errorCallback);
  }

  /**
   * Change virtual machine template
   * @param id
   * @param template
   * @param successCallback
   * @param errorCallback
   */
  changeVirtualMachineTemplate(
    id,
    template,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const payload = { template };
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_CHANGE_TEMPLATE, { id });
    this.postAPIRequest(url, payload, successCallback, errorCallback, "POST", [
      serverGeneratePath(URL_VIRTUAL_MACHINE, { id }),
    ]);
  }

  /**
   * Get virtual machine backups
   * @param id
   * @returns {{isLoading: boolean, error: any, backups: *[]}}
   */
  getVirtualMachineBackups(id, platform) {
    let base_url = `${URL_VIRTUAL_MACHINE_BACKUPS}?virtual_machine=${id}`;
    if (platform === "ctb" || platform === "contabo") {
      base_url = `${URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOTS}?contabo_vm=${id}`;
    }
    const { data, error, isLoading } = this.getAPIRequest(base_url);
    const virtualMachineBackup = new VirtualMachineBackup();
    const backups = virtualMachineBackup.fromArray(data);
    return { backups, error, isLoading };
  }

  /**
   * Restore virtual machine backup
   * @param id
   * @param vm_type
   * @param successCallback
   * @param errorCallback
   * @param progressCallback
   */
  restoreVirtualMachineBackup(
    id,
    vm_type,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void,
    progressCallback: (progress: any) => void
  ) {
    let restore_base_url = URL_VIRTUAL_MACHINE_BACKUP_RESTORE;
    if (vm_type === "ctb" || vm_type === "contabo") {
      restore_base_url = URL_CONTABO_VIRTUAL_MACHINE_SNAPSHOT_RESTORE;
    }
    const url = serverGeneratePath(restore_base_url, {
      id,
    });
    this.postAPIRequest(
      url,
      {},
      successCallback,
      errorCallback,
      progressCallback
    );
  }

  /**
   * Rebuid virtual machine
   * @param id
   * @param successCallback
   * @param errorCallback
   */
  rebuildVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const payload = {};
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_REBUILD, { id });
    this.postAPIRequest(url, payload, successCallback, errorCallback);
  }

  /**
   * Clone virtual machine
   * @param id
   * @param successCallback
   * @param errorCallback
   */
  cloneVirtualMachine(
    id,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const url = serverGeneratePath(URL_VIRTUAL_MACHINE_CLONE, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback, "POST", [
      URL_VIRTUAL_MACHINES,
    ]);
  }

  /**
   * Reset virtual machine password
   * @param id
   * @param platform
   * @param successCallback
   * @param errorCallback
   */
  resetVirtualMachinePassword(
    id,
    platform,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    let reset_url = URL_VIRTUAL_MACHINE_PASSWORD_RESET;
    if (platform === "ctb") {
      reset_url = URL_CONTABO_VIRTUAL_MACHINE_RESET_PASSWORD;
    }
    const url = serverGeneratePath(reset_url, { id });
    this.postAPIRequest(url, {}, successCallback, errorCallback, "POST", [
      URL_VIRTUAL_MACHINES,
    ]);
  }

  /**
   * Change virtual machine image
   * @param id
   * @param image
   * @param platform
   * @param successCallback
   * @param errorCallback
   */
  changeVirtualMachineImage(
    id,
    platform,
    image,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const payload = { image };
    let change_image_url = URL_VIRTUAL_MACHINE_CHANGE_IMAGE;
    if (platform === "ctb" || platform === "contabo") {
      change_image_url = URL_CONTABO_VIRTUAL_MACHINE_CHANGE_IMAGE;
    }
    const url = serverGeneratePath(change_image_url, { id });
    this.postAPIRequest(url, payload, successCallback, errorCallback, "POST", [
      serverGeneratePath(URL_VIRTUAL_MACHINE, { id }),
    ]);
  }

  /**
   * Change virtual machine display name
   * @param id
   * @param displayName
   * @param successCallback
   * @param errorCallback
   */
  changeVirtualMachineName(
    id,
    displayName,
    platform,
    successCallback: (error: any, response: any) => void,
    errorCallback: (error: any, response: any) => void
  ) {
    const payload = { display_name: displayName };
    let display_name_url = URL_VIRTUAL_MACHINE_CHANGE_DISPLAY_NAME;
    if (platform === "ctb" || platform === "contabo") {
      display_name_url = URL_CONTABO_VIRTUAL_MACHINE_CHANGE_DISPLAY_NAME;
    }
    const url = serverGeneratePath(display_name_url, { id });
    this.postAPIRequest(url, payload, successCallback, errorCallback, "POST", [
      serverGeneratePath(URL_VIRTUAL_MACHINE, { id }),
    ]);
  }
}
